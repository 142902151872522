import {LaneLibrary} from '@stratus/gss-ng-sdk';
import {IFormField} from '@app/run-planning/model/form-field';
import { Observable } from 'rxjs';

export interface ISampleDataRow extends LaneLibrary {
  laneNumbers?: number[];
  // cache config related info for load existing runContents to assign to config (only used in load-existing run)
  libraryPrepKitId?: string;
  indexAdapterKitId?: string;
  // marks sample row as mapped to ensure that sample is only mapped to one config (only used in load-existing run)
  isMappedToConfig?: boolean;
}

export interface IPerSampleSettingsDataRow {
  sampleId: string;
  /* Dynamically defined form fieldId-value key-value pairs */
  perSampleSettings: FormValueObject;
}

export type AnalysisLocation = 'Cloud' | 'Local';
export type AnalysisLocationLabels = 'Local' | 'BaseSpace' | 'BaseSpace / Illumina Connected Analytics';
// tslint:disable-next-line:interface-over-type-literal
export type FormValueObject = {[fieldId: string]: string};

export enum ReviewFormat {
  Always = 'always',
  DifferentFromDefault = 'differentFromDefault',
  Never = 'never'
}

export interface IConfigSummary {
  configTitle: string;
  analysisDescription: string;
  libraryPrepKit: string;
  indexAdapterKit: string;
  adapterRead1?: string;
  adapterRead2?: string;
  overrideCyclesValue: string;
  samplesSummary: string;
  referenceGenome?: string;
  physicalFormFields?: IFormField[];
  physicalFormValuesForDisplay?: {};
  logicalFormFields?: IFormField[];
  logicalFormValuesForDisplay?: any;
  analysisSettingsFormFields?: IFormField[];
  analysisSettingsFormValues?: any;
  // For compact run review mode
  compactSettingsForDisplay?: any;
  isArchived?: boolean;
}

export interface IRunConfigurationsSummary {
  analysisDefinition: string;
  analysisVersionDefinitionId: string;
  analysisVersionDefinitionDisplayName: string;
  physicalConfig: {};
  physicalFormValuesForDisplay: {};
  logicalConfigCount: number;
}

export interface INumCyclesReadValidator {
  read1LengthMax?: number;
  read1LengthMin?: number;
  read2LengthMax?: number;
  read2LengthMin?: number;
}

export interface ISampleTableColumnVisibility {
  showIndex1Column: boolean;
  showIndex2Column: boolean;
  showBarcodeIndex1Column: boolean;
  showBarcodeIndex2Column: boolean;

  // used to disable editing Sample name, Well position,I7 Index, Index 1, I5 Index, Index 2 fro ACT run
  disableLanes: boolean;
  disableSampleName: boolean;
  disableWellPosition: boolean;
  disableIndex1Column: boolean;
  disableIndex2Column: boolean;
  hideLanes: boolean;

  hideProjectColumn: boolean;
}

export interface IDynamicLoadingComponent {
  // observable to subscribe to the status change
  isLoading$: Observable<boolean>;
}

export enum NotificationToParentEventType {
  RunPrepRunHasError = 'run-planning.run-plan-error',
  RunPrepAuthenticationHasError = 'run-planning.run-plan-authentication-error',
  RunPrepIsSaved = 'run-planning.run-plan-saved',
  RunPrepIsSavedAsDraft = 'run-planning.run-plan-saved-as-draft',
  RunPrepIsCancelled = 'run-planning.run-plan-cancelled',
  RunPrepOtherError = 'run-planning.other-error',
  
  /* modal button click events */
  RunPrepModalCloseOK = 'run-planning.close-ok',
  RunPrepModalCloseCancel = 'run-planning.close-cancel',

  /** EdgeOS Custom File Browser (CFB) related events */
  EosCfbInitiate = 'run-planning.custom-file-browser-view.initiate',
  EosCfbDownloadTemplate = 'run-planning.custom-file-browser-view.download-template',

  /** Requeue related events */
  RunRequeueCookieIsMissing = 'run-planning.run-requeue-cookie-missing',
  RunRequeueSuccess = 'run-planning.run-requeue-success',
  RunRequeueFailed = 'run-planning.run-requeue-fail',

  /** ACT on EdgeOS related events */
  ActCreateSuccess = 'act.create-success',
  ActEditSuccess = 'act.edit-success',
  ActCreateOrEditCancelled = 'act.create-or-edit-cancelled',
  ActAuthenticationHasError = 'act.authentication-error',
  ActOtherError = 'act.other-error',
}

export enum NotificationFromParentMessage {
  RunPrepClosePageRequest = 'run-planning.close-request',

  /** EdgeOS Custom File Browser (CFB) related events */
  EosCfbLoaded = 'run-planning.custom-file-browser-view.load',
  EosCfbSelected = 'run-planning.custom-file-browser-view.selected',
  EosCfbCancelSelection = 'run-planning.custom-file-browser-view.cancel',
}

export interface IParentNotificationEvent {
  eventType: NotificationToParentEventType;
  payload: any;
}

export interface IRequeueInformation {
  runName: string;
  requeueReason?: string;
  dataFilePath?: string;
  sampleSheetRun?: string;
  analysisDataFilePath?: string;
}

export enum SubscriptionType {
  ICA = 'ica',
  BSSH = 'bssh',
}

export enum SettingName {
  RUN_UPLOAD_ICA_SETTING_NAME = 'run_upload_to_ica'
}

export enum RepeatSamplesAcrossLanesState {
  CHECKED = 1, // lane usage checkbox is displayed and checked
  UNCHECKED = 0,  // lane usage checkbox is displayed and unchecked
  HIDDEN = -1, // lane usage checkbox is not displayed (default value)
}
