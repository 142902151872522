import {INumCyclesReadValidator, NotificationToParentEventType} from '@app/run-planning/interface';
import {ConfigSetupState} from './config-setup/config-setup.state';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';

/* URL path run-settings segment */
export const PATH_RUN_SETTINGS_SEGMENT = 'run-settings';
/* URL path config-setup segment */
export const PATH_CONFIG_SETUP_SEGMENT = 'config-setup';
/* URL path sample-analysis-setup segment */
export const PATH_SAMPLE_ANALYSIS_SETUP_SEGMENT = 'sample-analysis-setup';
/* URL path summary segment */
export const PATH_SUMMARY_SEGMENT = 'summary';
/* URL path config segment preceding the config id */
export const PATH_CONFIG_SEGMENT = 'config';
/* URL segment path variable for config id */
export const CONFIG_ID_SEGMENT_KEY = 'config-id';
/* URL segment path variable for run id */
export const RUN_ID_SEGMENT_KEY = 'run-id';
/* URL query param variable for imported runs to trigger full validation */
export const IMPORTED_QUERY_PARAM_KEY = 'imported';
/* URL query param variable for requeue runs */
export const REQUEUE_QUERY_PARAM_KEY = 'requeue';
/* URL query param variable for launch run-planning from app */
export const APP_QUERY_PARAM_KEY = 'app';
/* URL query param variable for launch run-planning from app with version specified */
export const VERSION_QUERY_PARAM_KEY = 'version';
/* URL query param variable for run name */
export const RUN_NAME_QUERY_PARAM_KEY = 'runName';
/* URL query param variable for sample sehet file id */
export const FILE_ID_QUERY_PARAM_KEY = 'samplesheetfileId';
/* URL query param variable for standalone agent type */
export const AGENT_QUERY_PARAM_KEY = 'agent';

// Environment variables keys
export const INSTRUMENT_TYPE_ENV_KEY = 'instrumentType';

// Paths
export const RUN_PREP_SETUP_PATH = '/run-planning';
export const STANDALONE_ACT_PATH = '/analysis-configuration-templates';

// Changes saved toast message redirection delay timeout
export const CHANGES_SAVED_REDIRECTION_TIMEOUT_MS = 300;
export const CHANGES_SAVED_TOAST_MESSAGE = 'Changes have been saved.';

// Validation
export const FREE_TEXT_MAX_LENGTH = 255;
export const MIN_READ_1_LENGTH = 1;
export const MIN_READ_2_LENGTH_FOR_SINGLE_READ_TYPE = 0;
export const MIN_READ_2_LENGTH_FOR_PAIRED_READ_TYPE = 1;
export const MIN_INDEX_READ_LENGTH = 0;
export const MAX_INDEX_READ_LENGTH = 2147483647;
export const MAX_SAMPLE_DATA_TABLE_ROWS = 12288;
export const REGEX_VALIDATIONS = {
  freeText: new RegExp(/^[a-zA-Z0-9_\-][a-zA-Z0-9_\-\.\s]*$/),
  number: new RegExp(/^(\-?\d+\.{0,1}\d+|0|\-?[1-9]\d*)$/),
  integer: new RegExp(/^(0|[1-9]\d*)$/),
  restrictedNameRegex: new RegExp('^[a-zA-Z0-9_\-]{0,100}$'),
  sequenceRegex: new RegExp('^[AGCT]*$'),
  // pattern: 1 char of [Y,N,U] with followed by digits, pattern can be repeated. eg. Y151, Y76N75
  overrideCyclesReadRegex: new RegExp('^(?:[YNU]\\d+)+$'),
  // pattern: 1 char of [I,N,U] with followed by digits, pattern can be repeated. eg. I10, I8N2
  overrideCyclesIndexRegex: new RegExp('^(?:[INU]\\d+)+$'),
  adapterSequenceRegex: new RegExp('^[AGCT]+(?:\\+[AGCT]+?)*$'),
  // Run description pattern: does not accept square brackets, asterisk, and comma
  runDescriptionRegex: new RegExp(/^[^\[\]\*\,]*$/),
};

export const DEFAULT_READ_LENGTH_VALIDATOR: INumCyclesReadValidator = {
  read1LengthMax: 2147483647,
  read1LengthMin: MIN_READ_1_LENGTH,
  read2LengthMax: 2147483647,
  read2LengthMin: MIN_READ_2_LENGTH_FOR_PAIRED_READ_TYPE
};

export const LOCAL_FILE_NAME_PREFIX = 'fileName:';
/* Group name assigned to sample data rows without specified lane number (undefined) */
export const NO_LANE = 'NO_LANE';

// GSS API request params
/* Standard GSS list API pageSize limit, max limit of options to display on UI dropdown */
export const NUM_DROPDOWN_OPTIONS_LIMIT = 1000;

// URLs
export const PLANNED_RUNS_LIST_ROUTE = '/runs/planned';

// Analysis config name for implicit bcl convert in data transformation
export const IMPLICIT_BCL_CONVERT_CONFIG_NAME = 'implicit-bcl-convert';

// Physical configuration value if no physical field
export const EMPTY_PHYSICAL_FIELD_CONFIG_DISPLAY_TEXT = '(no reference genome)';

// Api error Keys
export const API_ERROR_KEY = 'api';
export const API_ERROR_MESSAGE_KEY = 'apiMessage';
export const API_ERROR_MESSAGES_KEY = 'apiMessages';

// Config limit exceed error Key
export const CONFIG_LIMIT_EXCEED_KEY = 'configLimitExceed';

// Function to generate CSV from data array
export function GenerateCSVFromDataArray(header: string[], rowsOfData: string[][]) {
  const rows = [header].concat(rowsOfData);
  return rows.map(e => e.join(',')).join('\n');
}

export const VALID_VALUES_FOR_CHECKBOX = { YES: true, NO: false, TRUE: true, FALSE: false };

export const OVERRIDE_CYCLES_FIELD_ID = 'OverrideCycles';

export const enum SUPPORTED_HIDDEN_UI_FIELDS { 
  overrideCycles = 'overrideCycles', 
  barcodeMismatchRead1 = 'barcodeMismatchRead1', 
  barcodeMismatchRead2 = 'barcodeMismatchRead2' 
};

export const enum Action {
  checkConfigsCountWithinLimit = 'checkConfigsCountWithinLimit',
  checkSampleNameUniqueAcrossConfigs = 'checkSampleNameUniqueAcrossConfigs',
  checkReadIndexValidity = 'checkReadIndexValidity',
  checkLpkAndIakValidAgainstReadLength = 'checkLpkAndIakValidAgainstReadLength',
  checkBclVersionCompatible = 'checkBclVersionCompatible',
  invokeRenderAvd = 'invokeRenderAvd',
  invokeFullValidation = 'invokeFullValidation',
  incrementalSavePlannedRun = 'incrementalSavePlannedRun',
  syncGlobalAnalysisSetting = 'syncGlobalAnalysisSetting',
  updateBclApplicationVersion = 'updateBclApplicationVersion',
  createDraftPlannedRun = 'createDraftPlannedRun',
  showUpdateReadLengthAlert = 'showUpdateReadLengthAlert',
  loadIndexInfoForAllConfigs = 'loadIndexInfoForAllConfigs',
  exportRunToValidate = 'exportRunToValidate',
  trimRunName = 'trimRunName',
}

export type ActionFunction = () => Observable<boolean>;

// Function to return config title
export function getConfigTitle(config: Partial<ConfigSetupState>, showConfigIndex = true) {
  const displayName = !isEmpty(config.avdDisplayName) ? config.avdDisplayName
    : getCombinedDisplayName([config.applicationDisplayName, getDragenVersionDisplayName(config.dragenVersion)]);
  if (showConfigIndex) {
    return `Configuration ${config.configIndex + 1}: ${displayName}`;
  }
  return `Configuration: ${displayName}`;
}

export function getDragenVersionDisplayName(dragenVersion: string) {
  return dragenVersion ? `(DRAGEN ${dragenVersion})` : '';
}

export function getCombinedDisplayName(names: string[]) {
  return names.filter(Boolean).join(' ');
}

// Parse dragon version from analysisVersionDefinitionDisplayName
export function getParsedDragenVersion(analysisVersionDefinitionDisplayName: string) {
  if (analysisVersionDefinitionDisplayName && analysisVersionDefinitionDisplayName.match(/.*\(DRAGEN .*\)/)) {
    return analysisVersionDefinitionDisplayName.split('(DRAGEN ')[1].split(')')[0];
  } else {
    return null;
  }
}

export const VALIDATION_ERROR_TYPE = {
  Partial_Validation_Error: 'PARTIAL_VALIDATION_ERROR',
  Full_Validation_Error: 'FULL_VALIDATION_ERROR',
  API_Error: 'API_ERROR'
};

/* Map HTTP Error code to event type */
export const ERROR_CODE_TO_EVENT = {
  401: NotificationToParentEventType.RunPrepAuthenticationHasError,
  403: NotificationToParentEventType.RunPrepAuthenticationHasError,
  409: NotificationToParentEventType.RunPrepRunHasError
};

/**
 * Given a name, determine if it is a bclConvert type
 */
export function isBclConvert(name: string) {
  const bclConvertAnalysisDefinitionNameRegex = /BCLConvert/i;
  let match = bclConvertAnalysisDefinitionNameRegex.test(name);

  // BASE-72726 the default analysis definition, FASTQGeneration will be updated to BCLConvert soon
  // (if the preferred bclConvert default is not found, fallback to the old BCLConvertName FASTQGeneration)
  if (!match) {
    const fallbackBclConvertAnalysisDefinitionNameRegex = [/(fastq)/i, /(generate|generation)/i];
    match = fallbackBclConvertAnalysisDefinitionNameRegex.every(r => r.test(name));
  }
  return match;
}

export const EMPTY_STRING = '__EMPTY_STRING__';

export function removeEmpty(obj: any) {
  if (isEmpty(obj)) {
    return;
  }

  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
    } else if (obj[key] == null || obj[key] === '') {
      delete obj[key];
    } else if (obj[key] === EMPTY_STRING) {
      obj[key] = '';
    }
  });
}

export const COOKIE_FOR_REQUEUE = {
  runName: 'EdgeOS.requeueRunName',
  requeueReason: 'EdgeOS.requeueReason',
  dataFilePath: 'EdgeOS.requeueDataFilePath',
  analysisDataFilePath: 'EdgeOS.requeueAnalysisDataFilePath',
  bsshRequeueRunName: 'BSSH.requeueRunName',
  path: '/',
  domain: '.illumina.com'
};

export const EDGEOS_UI_CSW_USER_AGENT_REGEX = new RegExp(/^.*Illumina CSW.*$/);

export enum InstrumentFileSystemMode {
  LOCAL_FILE_BROWSER = 'LOCAL_FILE_BROWSER',
  CUSTOM_FILE_BROWSER = 'CUSTOM_FILE_BROWSER',
}

export enum InstrumentAgentType {
  COMPUTER = 'COMPUTER',
  INSTRUMENT = 'INSTRUMENT',
}

export const ACT_LISTING_DEFAULT_SORT = 'timeCreated desc';
