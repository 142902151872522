
export class InstrumentTypes {
    public static readonly UNKNOWN = 'Unknown';
    public static readonly MISEQ = 'MiSeq';
    public static readonly HISEQ_2000 = 'HiSeq2000';
    public static readonly HISEQ_1000 = 'HiSeq1000';
    public static readonly HISCANSQ = 'HiScanSQ';
    public static readonly ECO = 'Eco';
    public static readonly ISCAN = 'iScan';
    public static readonly HISCAN = 'HiScan';
    public static readonly HISEQ_2500 = 'HiSeq2500';
    public static readonly HISEQ_1500 = 'HiSeq1500';
    public static readonly PROJECT_11 = 'Project11';
    public static readonly MISEQ_DX = 'MiSeqDx';
    public static readonly NEXTSEQ = 'NextSeq';
    public static readonly HISEQ_X = 'HiSeqX';
    public static readonly NEO_PREP = 'NeoPrep';
    public static readonly HISEQ_3000 = 'HiSeq3000';
    public static readonly HISEQ_4000 = 'HiSeq4000';
    public static readonly MINISeq = 'MiniSeq';
    public static readonly NOVASEQ_6000 = 'NovaSeq6000';
    public static readonly NEXTSEQ_DX = 'NextSeqDx';
    public static readonly NOVASEQ_5000 = 'NovaSeq5000';
    public static readonly ISEQ = 'iSeq';
    public static readonly ISEQ_100 = 'iSeq100';
    public static readonly TRUSIGHT_NIPT = 'TruSightNIPT';
    public static readonly NEXTSEQ_1000 = 'NextSeq1000';
    public static readonly NEXTSEQ_2000 = 'NextSeq2000';
    public static readonly NOVASEQ_6000_DX = 'NovaSeq6000Dx';
    public static readonly NOVASEQ_X_PLUS = 'NovaSeqXPlus';
    public static readonly NOVASEQ_X = 'NovaSeqX';
    public static readonly MISEQ_I100_PLUS = 'MiSeqi100Plus';
    public static readonly MISEQ_I100 = 'MiSeqi100';
    public static readonly SEQUENCER_TERRA = 'SequencerTerra';

    // obsolete - kept for backwards compatibility
    public static readonly INSTRUMENT_300M = 'Instrument300M'; // renamed to NovaSeqXPlus
    public static readonly INSTRUMENT_300M_LT = 'Instrument300MLT'; // renamed to NovaSeqX
    public static readonly SEQUENCE_R2D2 = 'SequenceR2D2'; // renamed to MiSeqi100Plus

    public static readonly REQUEUE_NOT_SUPPORTED_INSTRUMENT_TYPES = [InstrumentTypes.ECO,
                                                                 InstrumentTypes.ISCAN,
                                                                 InstrumentTypes.HISCAN];

    public static readonly REGENERATE_FASTQ_NOT_SUPPORTED_INSTRUMENT_TYPES =
                [
                    InstrumentTypes.UNKNOWN,
                    InstrumentTypes.ECO,
                    InstrumentTypes.ISCAN,
                    InstrumentTypes.HISCAN,
                    InstrumentTypes.NEXTSEQ,
                    InstrumentTypes.NEXTSEQ_DX,
                    InstrumentTypes.MINISeq
                ]


    public static ToPlatform(instrumentType: InstrumentTypes, throwError = false): string {
        switch (instrumentType) {
            case InstrumentTypes.HISCANSQ:
            case InstrumentTypes.HISEQ_1000:
            case InstrumentTypes.HISEQ_1500:
            case InstrumentTypes.HISEQ_2000:
            case InstrumentTypes.HISEQ_2500:
            case InstrumentTypes.HISEQ_3000:
            case InstrumentTypes.HISEQ_4000:
                return InstrumentPlatforms.HISEQ;

            case InstrumentTypes.MISEQ:
            case InstrumentTypes.MISEQ_DX:
                return InstrumentPlatforms.MINISEQ;

            case InstrumentTypes.NEXTSEQ:
            case InstrumentTypes.NEXTSEQ_DX:
            case InstrumentTypes.PROJECT_11:
                return InstrumentPlatforms.NEXTSEQ;

            case InstrumentTypes.HISCAN:
            case InstrumentTypes.ISCAN:
                return InstrumentPlatforms.ISCAN;

            case InstrumentTypes.HISEQ_X:
                return InstrumentPlatforms.HISEQ_X;

            case InstrumentTypes.ECO:
                return InstrumentPlatforms.ECO;

            case InstrumentTypes.NEO_PREP:
                return InstrumentPlatforms.NEO_PREP;

            case InstrumentTypes.MINISeq:
                return InstrumentPlatforms.MINISEQ;

            case InstrumentTypes.NOVASEQ_5000:
            case InstrumentTypes.NOVASEQ_6000:
            case InstrumentTypes.NOVASEQ_6000_DX:
                return InstrumentPlatforms.NOVASEQ;

            case InstrumentTypes.ISEQ_100:
                return InstrumentPlatforms.ISEQ;

            case InstrumentTypes.TRUSIGHT_NIPT:
                return InstrumentPlatforms.TRUSIGHT_NIPT;

            case InstrumentTypes.NEXTSEQ_1000:
            case InstrumentTypes.NEXTSEQ_2000:
                return InstrumentPlatforms.NEXTSEQ_1K_2K;
            
            case InstrumentTypes.NOVASEQ_X_PLUS:
            case InstrumentTypes.NOVASEQ_X:
            case InstrumentTypes.INSTRUMENT_300M:
            case InstrumentTypes.INSTRUMENT_300M_LT:
                return InstrumentPlatforms.NOVASEQ_X_SERIES;
            
            case InstrumentTypes.MISEQ_I100_PLUS:
            case InstrumentTypes.MISEQ_I100:
            case InstrumentTypes.SEQUENCE_R2D2:
                return InstrumentPlatforms.MISEQ_I100_SERIES;

            case InstrumentTypes.SEQUENCER_TERRA:
                return InstrumentPlatforms.SEQUENCER_TERRA;

            case InstrumentTypes.UNKNOWN:
                return InstrumentPlatforms.UNKNOWN;
            
            default:
                if (throwError) {
                    throw new Error('Unknown instrument type: ' + instrumentType);
                }
                return InstrumentPlatforms.UNKNOWN;
        }
    }
}

export class InstrumentPlatforms {
    public static UNKNOWN = 'Unknown';
    public static NEXTSEQ = 'NextSeq';
    public static MISEQ = 'MiSeq';
    public static HISEQ = 'HiSeq';
    public static ECO = 'Eco';
    public static ISCAN = 'iScan';
    public static HISEQ_X = 'HiSeqX';
    public static NEO_PREP = 'NeoPrep';
    public static MINISEQ = 'MiniSeq';
    public static NOVASEQ = 'NovaSeq';
    public static ISEQ = 'iSeq';
    public static TRUSIGHT_NIPT = 'TruSightNIPT';
    public static NEXTSEQ_1K_2K = 'NextSeq1k2k';
    public static NOVASEQ_X_SERIES = 'NovaSeqXSeries';
    public static MISEQ_I100_SERIES = 'MiSeqi100Series';
    public static SEQUENCER_TERRA = 'SequencerTerra';
    
    // obsolete - kept for backwards compatibility
    public static INSTRUMENT_300M = 'Instrument300M'; // renamed to NovaSeqXSeries
    public static SEQUENCE_R2D2 = 'SequenceR2D2'; // renamed to MiSeqi100Series
}
